import "core-js/modules/es.array.push.js";
import editDiv from "@/view/admin/adminEdit.vue";
import { getMenu } from "@/api/api";
export default {
  name: "menuList",
  components: {
    editDiv
  },
  data() {
    return {
      tableData: [],
      dataInfo: [],
      Info: null,
      dialogFormVisible: false,
      exportIds: ''
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      getMenu().then(res => {
        let list = res.data;
        for (let i in list) {
          list[i].children = list[i].child;
        }
        this.tableData = list;
      });
    },
    powerEdit(row) {
      let data = '';
      if (row) {
        data = JSON.stringify(row);
      }
      this.$root.useRouter.push({
        path: "/admin/userPower",
        query: {
          info: data
        }
      });
    },
    showEdit(row) {
      this.dialogFormVisible = true;
      this.Info = row;
      let data = '';
      if (row) {
        data = JSON.stringify(this.Info);
      }
      this.$refs.editDiv.getInfo(data); //调用子组件方法
    },
    showRenew(id) {
      this.dialogFormVisible1 = true;
      this.$refs.renewDiv.getInfo(id); //调用子组件方法
    },
    closeDiv() {
      this.dialogFormVisible = false;
    },
    handleSizeChange(e) {
      //修改每页显示条数
      this.pageInfo.limit = e;
      this.pageInfo.page = 1;
      this.getList();
    },
    handleCurrentChange(e) {
      this.pageInfo.page = e;
      this.getList();
    }
  }
};